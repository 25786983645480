.dashboards-title {
  margin-top: 30px;
  margin-bottom: 16px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.32px;
  color: #ffffff;
}

.dashboards-container {
  margin: 17px 0 0;
  padding: 30px 32px 32px 31px;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.1);
  background-color: #ffffff;
}

.dashboards-text {
  font-family: Inter;
  font-size: 16px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: #031125;
}

.dashboards-section-title {
  margin: 32px 0 16px 0;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.32px;
  color: #031125;
}

.ant-table-thead,
.dashboards-tableRow-even,
.dashboards-tableRow-odd {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: #1a1a1a;
}

.dashboards-tableRow-odd {
  background-color: #ffffff;
}

.dashboards-tableRow-even {
  background-color: #f7f8fc;
}

.ant-table-thead > tr > th,
.ant-table-thead {
  background-color: #031125;
  color: #ffffff;
}
