.metrics-item {
  width: 333px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgb(46 91 255 / 10%);
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.29px;
  color: #031125;
}

.metrics-title {
  margin-top: 30px;
  margin-bottom: 16px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.32px;
  color: #ffffff;
}

.metrics-container {
  display: flex;
  justify-content: space-between;
}
