.createDemoModal-title {
  margin-bottom: 16px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.32px;
  color: #ffffff;
}

.createDemoModal-text {
  width: 300px;
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  color: #1a1a1a;
}

.ant-modal-title {
  width: 224px;
  height: 21px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.32px;
  color: #1a1a1a;
}

.createDemoModal-button {
  width: 300px;
  height: 40px;
  margin: 32px 0 0;
  border-radius: 8px;
  background-image: linear-gradient(to right, #07274c, #031125);

  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.createDemoModal-input {
  width: 300px;
  height: 40px;
  margin: 12px 0;
  padding: 2.5px 123px 10px 15px;
  border-radius: 8px;
  border: solid 1px #031125;
  background-color: #ffffff;
}
