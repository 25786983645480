@import url(https://fonts.googleapis.com/css?family=Inter:700,500,300,600,800,400&subset=cyrillic,cyrillic-ext,latin);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-menu-horizontal {
  background: none;
  margin-top: 30px;
}

.ant-menu-item {
  color: #b2b2b2;
  border-bottom: 1px solid #b2b2b2;
}

.ant-menu-horizontal > .ant-menu-item a {
  font-family: Inter;
  font-size: 18px;
  letter-spacing: 0.32px;
  color: #b2b2b2;
}

.ant-menu-horizontal > .ant-menu-item a:hover,
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #ffffff;
}

.app-menuItem {
  color: #b2b2b2;
  border-bottom: 1px solid #b2b2b2;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #031125;
  background: #fff;
  border-color: #031125;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
  color: #ffffff;
  border-bottom: 2px solid #ffffff;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.32px;
}

.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.company-logo {
  width: 168px;
  height: 30px;
  object-fit: contain;
}

.app-container {
  background-image: linear-gradient(to top, #07274c, #031125);
  padding: 0 64px 115px;
  flex-grow: 1;
}

.app-headerPanel {
  /* background-color: #ffffff; */
  min-height: 80px;
  margin: 0 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app-header {
  width: 111px;
  height: 21px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.32px;
  color: #031125;
  margin: 0;
}

.app-openModalButton {
  width: 215px;
  height: 40px;
  border-radius: 8px;
  background-image: linear-gradient(to right, #07274c, #031125);

  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.dashboards-title {
  margin-top: 30px;
  margin-bottom: 16px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.32px;
  color: #ffffff;
}

.dashboards-container {
  margin: 17px 0 0;
  padding: 30px 32px 32px 31px;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.1);
  background-color: #ffffff;
}

.dashboards-text {
  font-family: Inter;
  font-size: 16px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: #031125;
}

.dashboards-section-title {
  margin: 32px 0 16px 0;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.32px;
  color: #031125;
}

.ant-table-thead,
.dashboards-tableRow-even,
.dashboards-tableRow-odd {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: #1a1a1a;
}

.dashboards-tableRow-odd {
  background-color: #ffffff;
}

.dashboards-tableRow-even {
  background-color: #f7f8fc;
}

.ant-table-thead > tr > th,
.ant-table-thead {
  background-color: #031125;
  color: #ffffff;
}

.marginL {
  margin-left: 30px;
}

.metrics-item {
  width: 333px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgb(46 91 255 / 10%);
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.29px;
  color: #031125;
}

.metrics-title {
  margin-top: 30px;
  margin-bottom: 16px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.32px;
  color: #ffffff;
}

.metrics-container {
  display: flex;
  justify-content: space-between;
}


.createDemoModal-title {
  margin-bottom: 16px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.32px;
  color: #ffffff;
}

.createDemoModal-text {
  width: 300px;
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  color: #1a1a1a;
}

.ant-modal-title {
  width: 224px;
  height: 21px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.32px;
  color: #1a1a1a;
}

.createDemoModal-button {
  width: 300px;
  height: 40px;
  margin: 32px 0 0;
  border-radius: 8px;
  background-image: linear-gradient(to right, #07274c, #031125);

  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.createDemoModal-input {
  width: 300px;
  height: 40px;
  margin: 12px 0;
  padding: 2.5px 123px 10px 15px;
  border-radius: 8px;
  border: solid 1px #031125;
  background-color: #ffffff;
}

.activateWelcome {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

